/*
 * Invalid inputs
 */

@keyframes shake {
  0% {
    margin-left: 0px;
    margin-right: 0px;
  }

  25% {
    margin-left: 5px;
    margin-right: -5px;
  }

  75% {
    margin-left: -5px;
    margin-right: 5px;
  }

  100% {
    margin-left: 0px;
    margin-right: 0px;
  }
}

*:has(> .shake-animation) {
  animation: shake 0.4s ease-in-out 0s;
}

.slide-from-right {
  animation: slideFromRight 0.3s ease-in-out forwards;
  opacity: 0; /* Optionally, you can start with opacity set to 0 */
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
