.k4d-logo {
  width: 100%;
  height: 100%;
  max-width: 125px;
  max-height: 42px;
  background-image: url("../images/logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .border-xs-none {
    border: none !important;
  }
}

@media (max-width: 767.98px) {
  .border-sm-none {
    border: none !important;
  }
}

@media (max-width: 991.98px) {
  .border-md-none {
    border: none !important;
  }
}

@media (max-width: 1199.98px) {
  .border-lg-none {
    border: none !important;
  }
}

@media (max-width: 1399.98px) {
  .border-xl-none {
    border: none !important;
  }
}

@media (min-width: 1400px) {
  .border-xxl-none {
    border: none !important;
  }
}
